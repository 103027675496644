<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: { Layout },
  data() {
    return {
      loading: false,
      success: false,

      types: null,
      type: null,

      alert: {
        username: { type: '', message: '' },
        transfer: { type: '', message: '' },
      },
    };
  },
  methods: {
    getTypes() {
      api
        .get('stakes/types')
        .then(response => {
          if (response.data.status == 'success') {
            this.types = response.data.list
          }
        })
    },
    createInvoice() {
      this.loading = true

      if (this.type && this.type.id) {
        api
          .post('stakes/invoices', {
            type: this.type.id,
          })
          .then(response => {
            if (response.data.status == 'success') {
              this.success = true
              this.$router.push('/store/order/' + response.data.order.id);
            } else {
              this.alert.type = 'alert-danger'
              this.alert.message = response.data.message
            }

            this.loading = false
          })
      }
    }
  },
  mounted() {
    this.getTypes()
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <div>
            <h4 class="mb-0 font-size-18">{{ t("Comprar") }}</h4>
          </div>
          <div class="ml-3">
            <button v-if="type" v-on:click="type = null" class="btn btn-light btn-sm">
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!type" class="">
      <div v-if="types" class="row">
        <div v-for="(t, index) in types" :key="index" class="col-md-6 col-lg-4">
          <template v-if="t.invoice && t.invoice.status && t.invoice.status == 'pending' && t.invoice.order">
            <router-link tag="a" :to="'/store/order/' + t.invoice.order" class="border-0 bg-transparent w-100 p-0 m-0 text-left">
              <div class="stake card">
                <div class="card-body bg-soft-default rounded">
                  <div class="d-flex align-items-center">
                    <div class="ml-2">
                      <img class="stake-cover" :src="require('@/assets/images/stakes/' + t.cover)" />
                    </div>
                    <div class="ml-3">
                      <h5 class="mb-0 text-dark text-uppercase">{{ t.name }}</h5>
                      <h4 class="mb-0 text-dark"><strong>{{ t.value | currency }}</strong></h4>
                      <strong class="bg-white rounded font-size-12 text-dark px-2">AGUARDANDO PAGAMENTO</strong>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </template>
          <template v-else-if="t.invoice && t.invoice.status && t.invoice.status == 'approved'">
            <router-link tag="a" :to="'/stakes'" class="border-0 bg-transparent w-100 p-0 m-0 text-left">
              <div class="stake card">
                <div class="card-body bg-soft-default rounded">
                  <div class="d-flex align-items-center">
                    <div class="ml-2">
                      <img class="stake-cover" :src="require('@/assets/images/stakes/' + t.cover)" />
                    </div>
                    <div class="ml-3">
                      <h5 class="mb-0 text-dark text-uppercase">{{ t.name }}</h5>
                      <h4 class="mb-0 text-dark"><strong>{{ t.value | currency }}</strong></h4>
                      <strong class="bg-white rounded font-size-12 text-dark px-2">ATIVO</strong>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </template>
          <template v-else-if="t.status == 'blocked'">
            <button class="border-0 bg-transparent w-100 p-0 m-0 text-left">
              <div class="stake card" :class="t.status">
                <div class="card-body bg-soft-default rounded">
                  <div class="d-flex align-items-center">
                    <div class="ml-2">
                      <img class="stake-cover" :src="require('@/assets/images/stakes/' + t.cover)" />
                    </div>
                    <div class="ml-3">
                      <h5 class="mb-0 text-dark text-uppercase">{{ t.name }}</h5>
                      <h4 class="mb-0 text-dark"><strong>{{ t.value | currency }}</strong></h4>
                      <strong class="bg-white rounded font-size-12 text-dark px-2">BLOQUEADO</strong>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </template>
          <template v-else>
            <button v-on:click="type = t" class="border-0 bg-transparent w-100 p-0 m-0 text-left">
              <div class="stake card" :class="t.status">
                <div class="card-body bg-soft-default rounded">
                  <div class="d-flex align-items-center">
                    <div class="ml-2">
                      <img class="stake-cover" :src="require('@/assets/images/stakes/' + t.cover)" />
                    </div>
                    <div class="ml-3">
                      <h5 class="mb-0 text-dark text-uppercase">{{ t.name }}</h5>
                      <h4 class="mb-0 text-dark"><strong>{{ t.value | currency }}</strong></h4>
                      <strong class="bg-success rounded font-size-12 text-dark px-2">LIBERADO PARA COMPRA</strong>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </template>
        </div>
      </div>
      <div v-else class="text-center">
        <b-spinner small class="ml-2 align-middle" variant="default" role="status"></b-spinner>
      </div>
    </div>
    <div v-else>
      <h6>Você escolheu a stake:</h6>
      <div class="stake card">
        <div class="card-body bg-soft-default rounded">
          <div class="d-flex align-items-center">
            <div class="">
              <img class="stake-cover" :src="require('@/assets/images/stakes/' + type.cover)" />
            </div>
            <div class="ml-3">
              <h5 class="mb-0 text-dark text-uppercase">{{ type.name }}</h5>
              <h4 class="mb-0 text-dark"><strong>{{ type.value | currency }}</strong></h4>
            </div>
          </div>
        </div>
      </div>
      <div v-if="alert.message" :class="'alert ' + alert.type">{{ t(alert.message) }}</div>
      <div class="card">
        <div class="card-body">
          <b-form class="p-2" @submit.prevent="createInvoice">
            <div class="text-center">
              <b-button :disabled="loading == true" type="submit" variant="default">
                Confirmar Compra
                <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </div>
          </b-form>
        </div>
      </div>

    </div>
  </Layout>
</template>

<style scoped>
.stake .card-body {
  background: rgb(17, 87, 39) !important;
  background: linear-gradient(270deg, rgba(17, 87, 39, 1) 0%, rgba(1, 33, 11, 1) 100%) !important;
  color: #fff;
}

.stake .card-body h4,
.stake .card-body h5 {
  color: #fff !important;
}

.stake-cover {
  max-width: 100px;
}

.stake.blocked .card-body {
  background: #555;
  filter: grayscale(1);
  opacity: 0.5;
}

.blocked img {
  opacity: 0.8;
}

.blocked h4,
.blocked h5,
.blocked p {
  color: #aaa !important;
}
</style>